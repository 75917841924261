.moetmyk{--padding:8px;position:fixed;inset:var(--padding);top:5rem;border-radius:6px;padding:var(--padding);padding-top:0;background:white;-webkit-transition:opacity 300ms;transition:opacity 300ms;box-shadow:0px 2px 4px hsl(0deg 0% 0% / 0.25),0px 4px 8px hsl(0deg 0% 0% / 0.2),0px 8px 16px hsl(0deg 0% 0% / 0.15),0px 16px 32px hsl(0deg 0% 0% / 0.12);overflow:auto;-webkit-animation:menuEnter-moetmyk 400ms cubic-bezier(0.17, 0.67, 0.51, 1);animation:menuEnter-moetmyk 400ms cubic-bezier(0.17, 0.67, 0.51, 1);will-change:transform;}@-webkit-keyframes menuEnter-moetmyk{from{-webkit-transform:translateX(25%);-moz-transform:translateX(25%);-ms-transform:translateX(25%);transform:translateX(25%);opacity:0;}}@keyframes menuEnter-moetmyk{from{-webkit-transform:translateX(25%);-moz-transform:translateX(25%);-ms-transform:translateX(25%);transform:translateX(25%);opacity:0;}}
.mf9l8x6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;min-height:100%;-webkit-animation:fadeIn 300ms backwards;animation:fadeIn 300ms backwards;-webkit-animation-delay:200ms;animation-delay:200ms;-webkit-transition:opacity 300ms;transition:opacity 300ms;}
.lp1k1ks{padding:0;margin:0;list-style-type:none;}
.l1ncp1oj{padding:4px 0;border-bottom:1px solid var(--color-gray-200);}
.f1v38vt5{-webkit-flex:1;-ms-flex:1;flex:1;}
.nvg9igy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:12px;padding:12px 8px;-webkit-text-decoration:none;text-decoration:none;color:inherit;}
.s1b89zcm{margin:calc(var(--padding) * -1);margin-top:var(--padding);}
.l352c3y{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:space-evenly;-ms-flex-pack:space-evenly;-webkit-justify-content:space-evenly;justify-content:space-evenly;padding:0;list-style-type:none;font-size:0.75rem;}
.l1p49fpz{position:relative;}
.l1c5s8om{display:block;padding:0.5rem;color:inherit;font-weight:var(--font-weight-medium);-webkit-text-decoration:none;text-decoration:none;text-align:center;}
