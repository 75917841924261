.w1klfgeh{--shadow-color:hsl(214deg 30% 60% / 0.2);container-type:inline-size;position:relative;margin-top:16px;border-radius:4px;overflow:hidden;overflow:clip;aspect-ratio:18/10;max-height:250px;background:linear-gradient(
    to top,
    var(--color-sky-to) 30%,
    var(--color-sky-from) 100%
  );box-shadow:inset 0px 1px 2px var(--shadow-color),inset 0px 2px 4px var(--shadow-color),inset 0px 4px 8px var(--shadow-color);}
.b1tpecs0{min-width:475px;width:85%;height:13rem;max-width:revert;position:absolute;right:-1px;bottom:0;}
.cdl3wy9{width:calc(100% + 1px);max-width:revert;position:absolute;left:-0.5px;right:-0.5px;bottom:-2px;}
.s10f3pnx{position:absolute;left:0;bottom:0;width:32%;max-width:150px;-webkit-transform:translateY(28%) rotate(20deg);-moz-transform:translateY(28%) rotate(20deg);-ms-transform:translateY(28%) rotate(20deg);transform:translateY(28%) rotate(20deg);}
.l1bbd16e{position:absolute;right:8px;bottom:8px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0;padding:0;padding-bottom:5%;list-style-type:none;}
.s2tykkp{display:block;padding:8px;padding-right:8px;color:inherit;-webkit-text-decoration:none;text-decoration:none;font-size:0.875rem;text-align:right;}
