.h1w8168s{position:-webkit-sticky;position:sticky;z-index:2;top:0;left:0;right:0;width:100%;margin-top:2.75rem;margin-inline:auto;background:linear-gradient(
    to top,
    transparent 0%,
    var(--top-color) 100%
  );-webkit-transition:--top-color calc(var(--color-swap-duration) + 600ms) var(--color-swap-timing-function);transition:--top-color calc(var(--color-swap-duration) + 600ms) var(--color-swap-timing-function);}html[data-color-mode='light'] .h1w8168s{--top-color:hsl(200deg 70% 78%);}@property --top-color{syntax:'<color>';inherits:true;initial-value:hsl(200deg 70% 78%);}html[data-color-mode='light'] .h1w8168s[data-is-over-threshold='true']{--top-color:hsl(0deg 0% 100%);-webkit-transition:--top-color calc(var(--color-swap-duration)) var(--color-swap-timing-function);transition:--top-color calc(var(--color-swap-duration)) var(--color-swap-timing-function);}html[data-color-mode='dark'] .h1w8168s{--top-color:hsl(214deg 40% 11%);}@property --top-color{syntax:'<color>';inherits:true;initial-value:hsl(214deg 40% 11%);}html[data-color-mode='dark'] .h1w8168s[data-is-over-threshold='true']{--top-color:hsl(210deg 15% 6%);-webkit-transition:--top-color calc(var(--color-swap-duration)) var(--color-swap-timing-function);transition:--top-color calc(var(--color-swap-duration)) var(--color-swap-timing-function);}@media (max-width: 50rem){.h1w8168s{margin-top:0;}}
.bui2te1{--extended-by:100px;--cutoff:calc(100% - var(--extended-by));position:absolute;inset:0;bottom:calc(var(--extended-by) * -1);-webkit-mask-image:linear-gradient(
    to bottom,
    black 0,
    black var(--cutoff),
    transparent var(--cutoff)
  );-webkit-mask-image:linear-gradient(
    to bottom,
    black 0,
    black var(--cutoff),
    transparent var(--cutoff)
  );mask-image:linear-gradient(
    to bottom,
    black 0,
    black var(--cutoff),
    transparent var(--cutoff)
  );-webkit-backdrop-filter:blur(8px);backdrop-filter:blur(8px);pointer-events:none;background:transparent;-webkit-transition:opacity 600ms;transition:opacity 600ms;}
